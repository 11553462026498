import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import FormHeader from '../../components/atoms/FormHeader';
import { Spin } from 'antd';
import query from 'query-string';
import axios from 'axios'
import './styles.scss';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { ArrowRightOutlined } from "@ant-design/icons";

const QuestionForm = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formDetails, setFormDetails] = useState(location.state && location.state.topicDetails ? location.state.topicDetails : {});
  const [params, setParams] = useState({});
  const [data, setData] = useState([]);
  const [sortedData, setSortedData] = useState([])
  const [recordingData, setRecordingData] = useState("")
  const [showModal, setShowModal] = useState(false);

  function calculateDurationFromTimeString(timeString) {
    // Split the input string into hours, minutes, and seconds
    const timeParts = timeString.split(' ');
  
    // Extract hours, minutes, and seconds
    const hours = parseInt(timeParts[0].replace('h', ''), 10) || 0;
    const minutes = parseInt(timeParts[1].replace('m', ''), 10) || 0;
    const seconds = parseInt(timeParts[2].replace('s', ''), 10) || 0;
  
    // Calculate the total duration in seconds
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
  
    return totalSeconds;
  }

  useEffect(() => {
    setParams(query.parse(location.search));
  }, [location.search]);

  useEffect(()=>{
    if(params.crnId){
      setLoading(true);
      axios.get(`https://meucci.bricknbolt.com/api/meucci/v2/callHistory/${params.crnId}`)
      .then(res=>{
        setData([...res.data.responseData])
      })
      .catch(err=>console.log(err))
      .finally(e=>setLoading(false))
    }
  },[params])

  useEffect(()=>{
    if(!isEmpty(data)){
      let temp = data.sort((a, b) => calculateDurationFromTimeString(b.call_duration) - calculateDurationFromTimeString(a.call_duration));
      let tempSortedData = {}
      temp.forEach(t=>{
        if(tempSortedData[t.date])
          tempSortedData[t.date].push(t)
        else
          tempSortedData[t.date] = [t]
      })
      let p = []
      Object.keys(tempSortedData).sort((a,b)=>{
        return new Date(b)-new Date(a)
      }).forEach(t=>{
        p.push(...tempSortedData[t])
      })
      setSortedData(p)
    }
  },[data])
  return (
    <>
      <div>
        <FormHeader headerTitle={formDetails.description} headerLeftClick={() => {
          navigate(-1);
        }} />
        <Spin spinning={loading}>
          {sortedData.length ? sortedData?.map((t,i)=>
            <>
              {(i==0 || (i!=0 && sortedData[i].date!=sortedData[i-1].date)) ? <div style={{marginLeft:"1rem", fontWeight:500, marginTop: i!=0?'1rem':"unset"}}>{t.date ? moment(t.date).format("DD MMMM, YYYY") : "-"}</div>:
              <></>}
              <div style={{ border:"1px solid #e8dddc", borderRadius:"6px", margin:"0.5rem 1rem"}} onClick={()=>{
                setRecordingData(t);
                setShowModal(true);
              }}>
                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                  <div style={{padding:"1rem", display:"flex"}}>
                    <div style={{fontWeight:500}}>Time : &nbsp;</div>{t.time}
                  </div>
                  <div style={{marginRight:"1rem"}}><ArrowRightOutlined /></div>
                </div>
              </div>
            </>
          ) : 
          <div style={{position:"relative", height:"80vh"}}>
            <div style={{position:"absolute", top:"50%", left:"50%", transform:"translate(-50%,-50%)", fontWeight:500, fontSize:"19px", color:'gray'}}>
              No Data
            </div>
          </div>
          }
        </Spin>
        <Modal
          title="Call Details"
          centered
          open={showModal}
          onOk={() => setShowModal(false)}
          onCancel={() => {
            setShowModal(false)
            let audioPlayer = document.getElementById('audioPlayer')
            audioPlayer.pause()
            audioPlayer.currentTime=0
          }}
          cancelText
          style={{textAlign:"center"}}
        >
          <div style={{ textAlign: "center" }}>
            <div style={{padding:".5rem 2rem", display:"flex"}}>
              <div style={{fontWeight:500}}>Agent Name : &nbsp;</div>{recordingData.agentName}
            </div>
            <div style={{padding:".5rem 2rem", display:"flex"}}>
              <div style={{fontWeight:500}}>Time : &nbsp;</div>{recordingData.time}
            </div>
            <div style={{padding:".5rem 2rem", display:"flex"}}>
              <div style={{fontWeight:500}}>Duration : &nbsp;</div>{recordingData.call_duration}
            </div>
            <div style={{padding:".5rem 2rem 1rem 2rem", display:"flex"}}>
              <div style={{fontWeight:500}}>Call Type : &nbsp;  </div>{recordingData.callType}
            </div>
            <audio controls id='audioPlayer'>
                <source src={recordingData.call_recording} type="audio/mpeg" />
                <source src={recordingData.call_recording} type="audio/ogg" />
                Your browser does not support the audio element.
            </audio>
          </div> 
        </Modal>
      </div>
    </>
  )
};

export default QuestionForm;
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import query from "query-string";
import { ArrowRightOutlined, CheckCircleFilled } from "@ant-design/icons";
import CustomCard from "../../components/atoms/CustomCard";
import HeadLogo from "../../components/atoms/HeadLogo";
import api from "../../utils/apiClient";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { message, Spin } from "antd";
import { TASK_TYPES_MAP } from "../../constants";
import AlertModal from "../../components/atoms/AlertModal";
import moment from "moment";
import CancellationModal from "../../components/molecules/CancellationModal";
import { HandleServerError } from "../../utils/serverErrorHandler";
import { isEmpty } from "lodash";

const MeetingDetails = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [meeting, setMeetingDetails] = useState({});
  const [params, setParams] = useState({});
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [userLocation, setUserLocation] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [alertDetails, setAlertDetails] = useState({});
  const [topics, setTopics] = useState([]);
  const [alertVisible, setAlertVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [topicFilterList, setTopicFilterList] = useState([])

  useEffect(() => {
    setParams(query.parse(location.search));
  }, [location.search]);

  useEffect(() => {
    if (params.token) {
      getMeetingTopics();
      getUserDetails();
    }
  }, [params.token]);

  useEffect(() => {
    if (params.latitude && params.longitude) {
      setUserLocation({
        latitude: params.latitude,
        longitude: params.longitude
      })
    }
  }, [params.latitude, params.longitude]);

  useEffect(() => {
    if (params.meetingId && params.token) {
      getMeetingDetails();
    }
  }, [params.meetingId]);

  const onSuccess = (text) => {
    messageApi.open({
      type: "success",
      content: text,
      icon: () => <></>,
      className: "custom-success-message",
      style: {
        position: "sticky",
        bottom: 0,
        top: "90%",
        zIndex: 99,
      },
    });
  };

  const onError = (text) => {
    messageApi.open({
      type: "error",
      content: text,
      icon: () => <></>,
      className: "custom-error-message",
      style: {
        position: "sticky",
        bottom: 0,
        top: "90%",
        zIndex: 99,
      },
    });
  };

  const getMeetingDetails = () => {
    setLoading(true);
    let url = `/spinozaService/api/spinoza/v1/meetings/${params.meetingId}`
    let crnId = localStorage.getItem('crnId')
    if(crnId){
      url += `/${crnId}`
    }
    api
      .get(
        url,
        {},
        {
          Authorization: `Bearer ${params.token}`,
        }
      )
      .then((res) => {
        setMeetingDetails(res.data.responseData);
        setLoading(false);
      })
      .catch((error) => {
        HandleServerError(error);
        setLoading(false);
      });
  };

  const getMeetingTopics = () => {
    api
      .get(
        `/spinozaService/api/v1/osMeeting/topics`,
        {},
        {
          Authorization: `Bearer ${params.token}`,
        }
      )
      .then((res) => {
        setTopics([
          {
            description:"Call Analytics",
            isHardCoded:true,
            redirectUrl: "/call-analytics",
            serverValue: "callAnalytics"
          },
          ...res.data.responseData]);
        setLoading(false);
      })
      .catch((error) => {
        HandleServerError(error);
        setLoading(false);
      });
  };

  const checkEndDisabled = () => {
    if (
      meeting &&
      (meeting.meetingStartTime === "" || meeting.meetingStartTime === null)
    ) {
      return true;
    } else if (meeting && meeting.meetingEndTime) {
      return true;
    }
    return false;
  };
  // console.log(meeting.isFormStarted &&
  //   meeting.isMeetingNotesSubmitted &&
  //   meeting.isCustomerNotesSubmitted &&
  //   meeting.isSiteInspectionNotesSubmitted &&
  //   meeting.isConstructionRequirementDetailsNotesSubmitted &&
  //   meeting.isNextStepsSubmitted &&
  //   !meeting.isFormSubmitted)
  //   console.log(meeting.showSubmitFormButton)
  //   console.log(meeting.formChecklist)
  const markMeetingStarted = (id) => {
    setLoading(true);
    api
      .post(
        `/spinozaService/api/spinoza/v1/meetings/${id}/startingMeeting`,
        {},
        {
          geoTracking: {
            latitude: userLocation.latitude,
            longitude: userLocation.longitude,
          },
        },
        {
          Authorization: `Bearer ${params.token}`,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "SUCCESS") {
            setAlertVisible(false);
            getMeetingDetails();
            onSuccess("Meeting marked started!");
            setLoading(false);
          } else {
            onError(response.data.errorMsg);
            setLoading(false);
          }
        } else {
          onError("Something went wrong");
          setLoading(false);
        }
      })
      .catch((err) => {
        HandleServerError(err);
        setLoading(false);
      });
  };

  const handleStartClick = () => {
    setAlertDetails({
      title: "Confirm start",
      body: `Would you like to start the ${TASK_TYPES_MAP[meeting.typeOfMeeting]
        } ?`,
      okText: "OK",
      cancelText: "Cancel",
      successCall: () => {
        if (userLocation) {
          markMeetingStarted(meeting.meetingId);
        }
      },
      errorCall: () => {
        setAlertVisible(false);
      },
    });
    setAlertVisible(true);
  };

  const completeMeeting = (id) => {
    setLoading(true);
    api
      .post(
        `/spinozaService/api/spinoza/v1/meetings/${id}/completeMeeting`,
        {},
        {
          geoTracking: {
            latitude: userLocation.latitude,
            longitude: userLocation.longitude,
          },
        },
        {
          Authorization: `Bearer ${params.token}`,
        }
      )
      .then(() => {
        setAlertVisible(false);
        getMeetingDetails();
        onSuccess("Meeting marked completed");
        setLoading(false);
      })
      .catch((err) => {
        HandleServerError(err);
        setLoading(false);
      });
  };
  const handleCompleteClick = () => {
    setAlertDetails({
      title: "Confirm complete",
      body: `Would you like to complete the meeting at ${meeting.locationOfMeeting} ?`,
      okText: "OK",
      cancelText: "Cancel",
      successCall: () => {
        if (userLocation) {
          completeMeeting(meeting.meetingId);
        }
      },
      errorCall: () => {
        setAlertVisible(false);
      },
    });
    setAlertVisible(true);
  };

  const cancelMeeting = (id, reason) => {
    if (userLocation) {
      setLoading(true);
      api
        .post(
          `/spinozaService/api/spinoza/v1/meetings/${id}/cancel`,
          {},
          {
            geoTracking: {
              latitude: userLocation.latitude,
              longitude: userLocation.longitude,
            },
            reason,
          }
        )
        .then(() => {
          getMeetingDetails();
          setCancelModalVisible(false);
          onSuccess("Meeting cancelled!");
          setLoading(false);
        })
        .catch((err) => HandleServerError(err));
    } else {
      onError(
        "Unable to fetch location, kindly ensure your GPS is on & try again!"
      );
      setLoading(false);
    }
  };

  const isStepCompleted = (step) => {
    let isComplete = false;
    switch (step) {
      case 0:
        isComplete = meeting && meeting.isFormStarted;
        break;
      case 1:
        isComplete = meeting && meeting.isMeetingNotesSubmitted;
        break;
      case 2:
        isComplete = meeting && meeting.isCustomerNotesSubmitted;
        break;
      case 3:
        isComplete = meeting && meeting.isCustomerDetailsSubmitted;
        break;
      case 4:
        isComplete = meeting && meeting.isSiteInspectionNotesSubmitted;
        break;
      case 5:
        isComplete =
          meeting && meeting.isConstructionRequirementDetailsNotesSubmitted;
        break;
      case 6:
        isComplete = meeting && meeting.isNextStepsSubmitted;
        break;
      case 7:
        isComplete = false;
        break;
      case 8:
        isComplete = meeting && meeting.isFormSubmitted;
        break;
      default:
        isComplete = false;
    }
    return isComplete;
  };

  const updateAllFormSubmitted = (id) => {
    setLoading(true);
    api
      .put(
        `/spinozaService/api/spinoza/v1/meetings/${id}/formSubmitted`,
        {},
        {
          geoTracking: {
            latitude: userLocation.latitude,
            longitude: userLocation.longitude,
          },
        },
        {
          Authorization: `Bearer ${params.token}`,
        }
      )
      .then(() => {
        getMeetingDetails();
        setAlertVisible(false);
        onSuccess("Form Submitted completed");
        setLoading(false);
      })
      .catch((err) => {
        HandleServerError(err);
        setLoading(false);
      });
  };

  const handleFormSubmitClick = () => {
    setAlertDetails({
      title: "Confirm submit",
      body: `Would you like to submit form  at ${meeting.locationOfMeeting} ?`,
      okText: "OK",
      cancelText: "Cancel",
      successCall: () => {
        updateAllFormSubmitted(meeting.meetingId);
      },
      errorCall: () => {
        setAlertVisible(false);
      },
    });
    setAlertVisible(true);
  };
  const getUserDetails = () => {
    api.get("/authService/userDetails",
      {},
      {
        Authorization: `Bearer ${params.token}`,
      }
    )
      .then((res) => {
        setUserDetails(res?.data?.responseData);
      }).catch((error) => {
        HandleServerError(error);
      });;
  };
  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(true);
    }
  }, [window && window.ReactNativeWebView]);

  useEffect(()=>{
    if(!isEmpty(meeting)){
      setTopicFilterList(meeting.visibleForms)
    }
  },[meeting])
  return (
    <div>
      <HeadLogo />
      <Spin spinning={loading}>
        <CustomCard cardTitle={meeting && meeting.crnId ? meeting.crnId : ""}>
          {contextHolder}
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          > */}
            {/* <div className="button-wrapper">
              <button
                disabled={
                  meeting &&
                  meeting.meetingStartTime !== "" &&
                  meeting.meetingStartTime !== null
                }
                className={classNames([
                  "meeting-event-btn",
                  "start-btn",
                  {
                    "start-active":
                      meeting &&
                      (meeting.meetingStartTime === "" ||
                        meeting.meetingStartTime === null),
                  },
                ])}
                onClick={() => handleStartClick()}
              >
                {"START"}
              </button>
              <button
                disabled={checkEndDisabled()}
                className={classNames([
                  "meeting-event-btn",
                  "end-btn",
                  { "end-active": !checkEndDisabled() },
                ])}
                onClick={() => handleCompleteClick()}
              >
                {"END"}
              </button>
            </div> */}
            {/* {meeting.isFormSubmitted && (
              <div style={{ marginTop: "-20px", marginRight: "-3px" }}>
                <label
                  style={{
                    backgroundColor: "#d3f8e1",
                    color: "#35c36a",
                    fontWeight: "600",
                    padding: "5px 10px",
                    borderTopRightRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                >
                  {"SUBMITTED"}
                </label>
              </div> */}
            {/* )}
          </div> */}
          <div>{meeting?.meetingTitle}</div>
          <div className="meeting-time-wrapper">
            {meeting && meeting.meetingStartTime && (
              <label className="bold-label">{`Start: ${moment(
                meeting.meetingStartTime
              ).format("DD-MM-YYYY hh:mm A")}`}</label>
            )}
            {meeting && meeting.meetingEndTime && (
              <label className="bold-label">{`End: ${moment(
                meeting.meetingEndTime
              ).format("DD-MM-YYYY hh:mm A")}`}</label>
            )}
          </div>
          <hr className="hr-style" />
          <Spin spinning={!(topics && topicFilterList?.length)}>
            {topics.map((item, idx) => {
              if(!topicFilterList.includes(item.serverValue)) return 
              return item?.isHardCoded==true?
                <div
                key={item.id}
                className="form-name-row"
                onClick={() => {
                  if (
                    item.id === "6363a5b183d40f070eab79d3" &&
                    meeting?.formChecklist?.[item?.serverValue] &&
                    !meeting.isFormSubmitted
                  ) {
                    setAlertDetails({
                      title: "",
                      body: `Your task has already been submitted, do you still want to go ahead and create a new task?`,
                      okText: "Yes, Proceed",
                      cancelText: "Cancel",
                      successCall: () => {
                        navigate(
                          `/form${location.search}&formId=${item.id}&crnId=${meeting.crnId}`,
                          {
                            state: {
                              topicDetails: item,
                              initialForm: idx === 0,
                              quotationForm:
                                item.id === "6363a66983d40f070eab79d5",
                              topicsList: topics,
                              userDetails: userDetails,
                            },
                          }
                        );
                      },
                      errorCall: () => {
                        setAlertVisible(false);
                        return;
                      },
                    });
                    setAlertVisible(true);
                    return;
                  }
                  navigate(
                    `${item.redirectUrl}?crnId=${meeting.crnId}`,
                    {
                      state: {
                        topicDetails: item,
                        initialForm: idx === 0,
                        quotationForm: item.id === "6363a66983d40f070eab79d5",
                        topicsList: topics,
                        userDetails: userDetails,
                      },
                    }
                  );
                }}
              >
                <label className="form-name">{item.description}</label>
                <div>
                  {meeting?.formChecklist?.[item?.serverValue] ? (
                    <CheckCircleFilled style={{ color: "#40e980" }} />
                  ) : (
                    <ArrowRightOutlined />
                  )}
                </div>
              </div>:
              <div
              key={item.id}
              className="form-name-row"
              onClick={() => {
                if (
                  item.id === "6363a5b183d40f070eab79d3" &&
                  meeting?.formChecklist?.[item?.serverValue] &&
                  !meeting.isFormSubmitted
                ) {
                  setAlertDetails({
                    title: "",
                    body: `Your task has already been submitted, do you still want to go ahead and create a new task?`,
                    okText: "Yes, Proceed",
                    cancelText: "Cancel",
                    successCall: () => {
                      navigate(
                        `/form${location.search}&formId=${item.id}&crnId=${meeting.crnId}`,
                        {
                          state: {
                            topicDetails: item,
                            initialForm: idx === 0,
                            quotationForm:
                              item.id === "6363a66983d40f070eab79d5",
                            topicsList: topics,
                            userDetails: userDetails,
                          },
                        }
                      );
                    },
                    errorCall: () => {
                      setAlertVisible(false);
                      return;
                    },
                  });
                  setAlertVisible(true);
                  return;
                }
                if (!meeting.isMeetingDone && item.serverValue!='customerNotes') {
                  onError("Please start the meeting");
                  return;
                }
                navigate(
                  `/form${location.search}&formId=${item.id}&crnId=${meeting.crnId}`,
                  {
                    state: {
                      topicDetails: item,
                      initialForm: idx === 0,
                      quotationForm: item.id === "6363a66983d40f070eab79d5",
                      topicsList: topics,
                      userDetails: userDetails,
                    },
                  }
                );
              }}
            >
              <label className="form-name">{item.description}</label>
              <div>
                {meeting?.formChecklist?.[item?.serverValue] ? (
                  <CheckCircleFilled style={{ color: "#40e980" }} />
                ) : (
                  <ArrowRightOutlined />
                )}
              </div>
            </div>
          })}
          </Spin>
        </CustomCard>

        {meeting.shouldShowCancelButton && (
          <>
            <div
              onClick={() => setCancelModalVisible(true)}
              style={{ margin: "20px" }}
            >
              <label style={{ color: "red" }}>CANCEL</label>
            </div>
            <hr className="hr-style" />
          </>
        )}
        {meeting?.showSubmitFormButton && (
        // {meeting.isFormStarted &&
        //   meeting.isMeetingNotesSubmitted &&
        //   meeting.isCustomerNotesSubmitted &&
        //   meeting.isSiteInspectionNotesSubmitted &&
        //   meeting.isConstructionRequirementDetailsNotesSubmitted &&
        //   meeting.isNextStepsSubmitted &&
        //   !meeting.isFormSubmitted && (          
            <>
              <div
                onClick={handleFormSubmitClick}
                style={{
                  backgroundColor: "#228B22",
                  padding: "10px",
                  borderRadius: "5px",
                  maxWidth: "25%",
                  margin: "20px",
                }}
              >
                <label style={{ color: "white", textAlign: "center" }}>
                  Submit Form
                </label>
              </div>
              <hr className="hr-style" />
            </>
          )}
      </Spin>
      <CancellationModal
        meeting={meeting}
        visible={cancelModalVisible}
        onCancel={() => setCancelModalVisible(false)}
        onSubmit={(payload) => cancelMeeting(payload)}
        onError={onError}
        onSuccess={onSuccess}
      />
      <AlertModal
        visible={alertVisible}
        alertTitle={alertDetails.title}
        bodyContent={alertDetails.body}
        okText={alertDetails.okText}
        cancelText={alertDetails.cancelText}
        onSuccess={alertDetails.successCall}
        onCancel={alertDetails.errorCall}
      />
    </div>
  );
};

export default MeetingDetails;
